import { handle, json } from "next-runtime";
import mailchimp from "@mailchimp/mailchimp_marketing";

import { Header } from "../components/Header";
import { HeroSection } from "../components/HeroSection";
import { Footer } from "../components/Footer";
import { HowItWorksSection } from "../components/HowItWorksSection";
import { RainbowDivider } from "../components/RainbowDivider";

export const getServerSideProps = handle({
  async get() {
    return json<{ message?: string }>({});
  },
  async post({ req: { body } }) {
    if (!body?.email) {
      return json<{ message?: string }>({ message: "Email is required" }, 400);
    }

    const email = body?.email as string;

    mailchimp.setConfig({
      apiKey: process.env.MAILCHIMP_API_KEY,
      server: process.env.MAILCHIMP_API_SERVER,
    });

    try {
      await mailchimp.lists.addListMember(process.env.MAILCHIMP_AUDIENCE_ID!, {
        email_address: email,
        status: "subscribed" as mailchimp.Status,
        tags: ["notify-me"],
      });
      return json({
        message: "Added to the list. Thanks for subscribing! 🎉",
      });
    } catch (error) {
      if ((error as any).response.body.title === "Member Exists") {
        return json<{ message?: string }>(
          { message: "Hmmm...Looks like you're already subscribed 😅!" },
          400
        );
      } else {
        console.error(error);
        return json(
          {
            message:
              "There was an issue saving your email. Please try again in a little bit",
          },
          500
        );
      }
    }
  },
});

export default function Home() {
  return (
    <main>
      <Header />
      <HeroSection />
      <RainbowDivider />
      <HowItWorksSection />
      <Footer />
    </main>
  );
}
