import clsx from "clsx";
import Link from "next/link";
import Image from "next/image";
import { usePlausible } from "next-plausible";
import { Form, useFormSubmit } from "next-runtime/form";
import { FetchError } from "next-runtime/lib/fetch-error";
import { ChevronRightIcon } from "@heroicons/react/solid";

function isFetchError(err: unknown): err is FetchError {
  return err instanceof FetchError;
}

type Data = {
  message: string;
};

export function HeroSection({ className = "" }: { className?: string }) {
  const plausible = usePlausible();
  const { data, values, error, isSubmitting, isSuccess, isError } =
    useFormSubmit<Data>("notify-me");
  return (
    <section
      className={clsx(
        "flex flex-col justify-evenly space-y-10 px-4 sm:px-8 md:px-16 py-10 bg-gray-800 text-gray-400",
        className
      )}
    >
      <div className="flex flex-1 items-center md:space-x-16">
        <div className="flex flex-1 flex-col space-y-8 md:min-w-[500px] md:max-w-[500px]">
          <h1 className="font-extrabold tracking-tight text-4xl sm:text-5xl md:text-[55px] sm:leading-[55px] text-center sm:text-left">
            <span className="text-yellow-300">Render challenges.</span>
            <br />
            <span className="text-white">Right in your inbox</span>
          </h1>
          <p className="text-xl sm:leading-8 font-light text-center sm:text-left">
            Get notified of new render challenges every Friday, so you can make
            the most out of your weekend.
          </p>
          <Form
            name="notify-me"
            method="post"
            encType="multipart/form-data"
            className="flex flex-col space-y-4"
            onSuccess={() => {
              plausible("notify-me-success");
            }}
            onError={() => {
              if (isFetchError(error)) {
                plausible("notify-me-error", {
                  props: {
                    error: error?.data?.message as string,
                  },
                });
              } else {
                plausible("notify-me-error", {
                  props: {
                    error: `Unexpected error [error=${
                      error?.message ?? error?.name ?? "Unknown"
                    }]`,
                  },
                });
              }
            }}
          >
            {isSuccess ? (
              <p className="text-xl font-semibold sm:leading-8 text-center sm:text-left">
                {data?.message as string}
              </p>
            ) : isSubmitting ? (
              <p className="text-xl font-semibold sm:leading-8 text-center sm:text-left">
                Submitting...
              </p>
            ) : (
              <>
                <div className="flex flex-col space-y-2 sm:flex-row sm:space-x-4 sm:space-y-0">
                  <input
                    name="email"
                    type="email"
                    placeholder="Enter your email"
                    aria-describedby="no-spam error"
                    defaultValue={values?.email as string}
                    className={clsx(
                      "flex-1 px-4 py-3 rounded-md border bg-transparent focus:bg-white focus:text-gray-900 border-gray-500 font-light placeholder-gray-500 text-gray-100",
                      isError && "border-red-500"
                    )}
                  />
                  <button
                    type="submit"
                    className="px-5 py-3 rounded-md bg-blue-500 text-white font-semibold whitespace-nowrap"
                  >
                    Notify Me
                  </button>
                </div>
                {isError ? (
                  <p
                    id="error"
                    className="text-xs font-normal text-center sm:text-left text-red-500"
                  >
                    {isFetchError(error) ? (
                      (error?.data?.message as string)
                    ) : (
                      <>
                        An unexpected error occurred. Try{" "}
                        <button
                          onClick={() => window.location.reload()}
                          className="hover:underline font-semibold"
                        >
                          refreshing
                        </button>{" "}
                        the page
                      </>
                    )}
                  </p>
                ) : (
                  <p
                    id="no-spam"
                    className="text-xs text-center sm:text-left text-gray-500"
                  >
                    We will never spam you
                  </p>
                )}
              </>
            )}
          </Form>
        </div>
        <div className="flex-1 hidden lg:grid lg:place-items-center ">
          <Image
            width={400}
            height={400}
            src="/img/mail-dynamic-color.png"
            alt="3D email letter illustration"
          />
        </div>
      </div>

      <div className="flex flex-col space-y-10">
        <hr className="border-gray-700" />
        <div className="flex justify-between items-center">
          <div className="hidden md:flex md:space-x-16">
            <div className="flex flex-col space-y-0">
              <span className="text-3xl text-white font-extrabold">100+</span>
              <span className="text-gray-400 font-light">Challenges</span>
            </div>
            <div className="flex flex-col space-y-0">
              <span className="text-3xl text-white font-extrabold">100+</span>
              <span className="text-gray-400 font-light">Subscribers</span>
            </div>
            <div className="flex flex-col space-y-0">
              <span className="text-3xl text-white font-extrabold">25+</span>
              <span className="text-gray-400 font-light">Emails so far</span>
            </div>
          </div>
          <div className="flex-1 grid place-items-center md:place-items-end">
            <Link href="/faq#what-is-a-render-challenge" passHref>
              <a className="text-white uppercase text-xs font-semibold flex items-center space-x-2 hover:animate-pulse">
                <span>What is a Render Challenge?</span>
                <ChevronRightIcon
                  className="block h-6 w-6"
                  aria-hidden="true"
                />
              </a>
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
}
