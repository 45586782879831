import type { PropsWithChildren } from "react";

export function Badge({ children }: PropsWithChildren<{}>) {
  return (
    <div className="bg-blue-50 text-blue-400/80 border-[1px] border-blue-100/40 px-3 py-1 rounded-full">
      <span className="uppercase tracking-wider text-xs font-semibold whitespace-nowrap">
        {children}
      </span>
    </div>
  );
}
