import Image from "next/image";
import Link from "next/link";
import { Badge } from "./Badge";
import { RainbowDivider } from "./RainbowDivider";

export function HowItWorksSection() {
  return (
    <section id="how-it-works" className="relative overflow-hidden">
      <h2 className="sr-only">How It Works</h2>

      {/* Marquee */}
      <div className="relative flex overflow-x-hidden" aria-hidden="true">
        <div className="py-8 motion-safe:animate-marquee whitespace-nowrap">
          <span className="text-3xl md:text-[48px] leading-none font-black text-gray-200 uppercase tracking-tight mx-2">
            How It Works • How It Works • How It Works • How It Works • How It
            Works •{" "}
          </span>
        </div>

        <div className="absolute top-0 py-8 motion-safe:animate-marquee2 whitespace-nowrap">
          <span className="text-3xl md:text-[48px] leading-none font-black text-gray-200 uppercase tracking-tight mx-2">
            How It Works • How It Works • How It Works • How It Works • How It
            Works •{" "}
          </span>
        </div>
      </div>

      {/* Rainbow Glow */}
      <div className="absolute top-0 left-0 right-0" aria-hidden="true">
        <RainbowDivider className="blur-3xl absolute top-0 left-0 right-0" />
        <RainbowDivider className="blur-xl absolute top-0 left-0 right-0" />
      </div>

      {/* Content */}
      <div className="flex flex-col items-center px-4 sm:px-8 md:px-16 py-8 space-y-16 lg:flex-row lg:space-x-16 lg:space-y-0">
        <div className="flex-1 grid place-items-center">
          <Image
            width={400}
            height={400}
            src="/img/trophy-dynamic-color.png"
            alt="3D trophy illustration"
          />
        </div>
        <div className="flex flex-1 flex-col space-y-6 items-start">
          <h3 className="font-extrabold tracking-tight leading-none text-3xl sm:text-5xl whitespace-nowrap">
            <span className="text-blue-500">All the best challenges.</span>
            <br />
            <span className="text-gray-800">All in one place</span>
          </h3>
          <p className="text-xl sm:leading-8 font-light text-gray-600">
            We update our challenge database{" "}
            <strong className="font-semibold">every week</strong> and send out a
            challenge roundup email to all our subscribers.
          </p>
          <Link href="/challenges" passHref>
            <a className="px-5 py-3 rounded-md bg-blue-500 text-white font-semibold whitespace-nowrap">
              Go to Challenge Database
            </a>
          </Link>
        </div>
      </div>

      <div className="flex flex-col items-center px-4 sm:px-8 md:px-16 py-8 space-y-16 lg:flex-row-reverse lg:space-x-16 lg:space-y-0">
        <div className="flex-1 grid place-items-center">
          <Image
            width={400}
            height={400}
            src="/img/chat-bubble-dynamic-color.png"
            alt="3D chat bubble illustration"
          />
        </div>
        <div className="flex flex-1 flex-col space-y-6 items-start">
          <Badge>Early Access</Badge>
          <h3 className="font-extrabold tracking-tight leading-none text-3xl sm:text-5xl whitespace-nowrap">
            <span className="text-pink-500">Share your WIP.</span>
            <br />
            <span className="text-gray-800">Get feedback and support</span>
          </h3>
          <p className="text-xl sm:leading-8 font-light text-gray-600">
            Upload screenshots and recordings to our{" "}
            <Link href="/wip">
              <a className="font-bold bg-clip-text text-transparent bg-gradient-to-r from-yellow-500 via-teal-500 to-violet-500 hover:animate-pulse">
                Work In Progress
              </a>
            </Link>{" "}
            app to get a permanent link that you can share with others to
            comment and annotate as you work.
          </p>
          <Link href="/wip" passHref>
            <a className="px-5 py-3 rounded-md bg-pink-500 text-white font-semibold whitespace-nowrap">
              Sign up for Waitlist
            </a>
          </Link>
        </div>
      </div>

      <div className="flex flex-col items-center px-4 sm:px-8 md:px-16 py-8 space-y-16 lg:flex-row lg:space-x-16 lg:space-y-0">
        <div className="flex-1 grid place-items-center">
          <Image
            width={400}
            height={400}
            src="/img/rocket-dynamic-color.png"
            alt="3D rocket blasting off illustration"
          />
        </div>
        <div className="flex flex-1 flex-col space-y-6 items-start">
          <Badge>Coming Soon</Badge>
          <h3 className="font-extrabold tracking-tight leading-none text-3xl sm:text-5xl whitespace-nowrap">
            <span className="text-emerald-400">Nothing suit your fancy?</span>
            <br />
            <span className="text-gray-800">Host your own challenge</span>
          </h3>
          <p className="text-xl sm:leading-8 font-light text-gray-600">
            That&apos;s right, soon you&apos;ll be able to{" "}
            <strong className="font-semibold">
              host your own render challenges
            </strong>{" "}
            right here on renderchallenge.com with custom rules, judging, and
            prizes!
          </p>
        </div>
      </div>
    </section>
  );
}
