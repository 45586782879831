export function Footer() {
  return (
    <footer className="py-4 px-16 bg-gray-200 flex justify-between items-center">
      <p className="text-gray-500 text-xs">
        Copyright ©{" "}
        {Intl.DateTimeFormat("en-US", { year: "numeric" }).format(Date.now())}{" "}
        Render Challenge Company.
      </p>
      <a
        href="https://www.buymeacoffee.com/renderchallenge"
        target="_blank"
        rel="noreferrer"
      >
        {/* eslint-disable-next-line @next/next/no-img-element */}
        <img
          src="https://cdn.buymeacoffee.com/buttons/v2/default-red.png"
          alt="Buy Me A Coffee"
          className="h-10 w-auto"
        />
      </a>
    </footer>
  );
}
