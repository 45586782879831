import clsx from "clsx";

export function RainbowDivider({ className }: { className?: string }) {
  return (
    <div
      className={clsx("h-[6px]", className)}
      style={{
        backgroundImage:
          "linear-gradient(90deg, #FFC83A 9.9%, #FF008A 39.58%, #001AFF 66.15%, #17DBA0 90.63%)",
      }}
    />
  );
}
